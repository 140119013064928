import BaseInterface from '../baseInterface';
import { notificationTypes } from 'WrapperService/utils/notificationTypes';
import Queue from '../../../utils/queue';

export default class SystemUIInterface extends BaseInterface {
  constructor(...props) {
    super(...props);
    this._popupScreensQueue = new Queue(this._executeScreen.bind(this));
    this._popupScreensActive = false;
  }

  handleModelChange({ partnerConfig, tournamentsInfo, gameInited, preloadingComplete, rounds_history }) {
    this._Controller.partnerConfig = partnerConfig;
    if (!this._controllersInited) {
      this._controllersInited = true;
      this.controllers[this.controllerTypes.ECT_SIDEBAR].init();
    }

    if (gameInited && tournamentsInfo) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].updateData(tournamentsInfo);
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].updateData(tournamentsInfo);
    }

    if (preloadingComplete && !this._popupScreensActive) {
      this._popupScreensActive = true;
      this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN]);
      this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN]);
      this._popupScreensQueue.add(this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END]);
    }

    this.controllers[this.controllerTypes.ECT_ROUNDS_HISTORY].updateData(rounds_history);
  }

  showNotification(type, data) {
    this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].showNotification(type, data);
  }

  hideNotification(type, data) {
    this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].hideNotification(type, data);
  }

  handleTournamentStart(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN].handleStart(tournamentId);
    this.controllers[this.controllerTypes.ECT_SIDEBAR].tryInitTournamentsButton();
  }

  handleTournamentEnd(tournamentId) {
    this.controllers[this.controllerTypes.ECT_MODAL_TOURNAMENT_END].handleEnd(tournamentId);
  }

  handleTournamentAnnouncement(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN].handleUpcoming(tournamentId);
  }

  showTournamentResults(tournamentId) {
    this.controllers[this.controllerTypes.ECT_TOURNAMENTS].openArchiveTournament(tournamentId);
    this.toggleControllerVisibility(this.controllerTypes.ECT_TOURNAMENTS, true);
  }

  toggleControllerVisibility(type, visibility) {
    const controller = this.controllers[type];
    if (!controller) return console.error(`Unhandled controller type: '${type}'`);

    const condition = visibility !== undefined ? visibility : controller.isHidden;

    if (condition) {
      controller.show();
    } else {
      controller.hide();
    }
  }

  _executeScreen(controller) {
    return new Promise(async resolve => {
      controller.canBeShown = true;
      await controller.execution;
      resolve();
    })
  }

  get eNotificationTypes () {
    return this.controllers[this.controllerTypes.ECT_NOTIFICATIONS].eNotificationTypes;
  }

  get systemNotificationTypes () {
    return notificationTypes;
  }
}
